import iFrameBookApiClient from '../book';
import iFrameApiClient from '../ecom';
import { Endpoints } from '../../../network';

export const getBookTokenIFrame = async () => {
  const response = await iFrameBookApiClient.get(`${Endpoints.getBookToken}`);
  return response?.data;
};

export const getBookByIdApiIFrame = async (id: string) => {
  const response = await iFrameBookApiClient.get(
    `${Endpoints.getAllBooks}/${id}`
  );
  return response?.data?.data;
};

export const tempUserIFrame = async ({
  product_id,
  code,
  email,
  user_id,
  source,
}: {
  product_id: string;
  code: string;
  email: string;
  user_id: string;
  source: string;
}) => {
  const response = await iFrameApiClient.post(`${Endpoints.tempUser}`, {
    product_id,
    code,
    email,
    user_id,
    source,
  });
  return response?.data?.data;
};

export const checkAffiliateCode = async ({ code }: { code: string }) => {
  const response = await iFrameApiClient.get(
    `${Endpoints.checkAffiliateCode}?code=${code}`
  );
  return response?.data.message;
};
