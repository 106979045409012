import axios, { AxiosRequestConfig } from 'axios';

const iFrameBookApiClient = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_READER_URL,
  timeout: 300000,
});

let token: string | undefined = undefined;

if (typeof localStorage !== 'undefined') {
  const encodedToken = localStorage.getItem('authIframe');
  if (encodedToken) {
    token = atob(encodedToken);
  }
}

iFrameBookApiClient.interceptors.request.use((config: AxiosRequestConfig) => {
  if (token && config.headers) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

iFrameBookApiClient.interceptors.response.use((response: { data: null }) => {
  return response;
});

export default iFrameBookApiClient;
