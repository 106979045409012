/* eslint-disable @next/next/no-img-element */
import {
  RotateLeftOutlined,
  RotateRightOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { BookOpenIcon, EyeIcon, XIcon } from '@heroicons/react/outline';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { Affix, Button, Layout, Tooltip } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { AppwriteException } from 'appwrite';
import clsx from 'clsx';
import { motion, useAnimation } from 'framer-motion';
import moment, { Moment } from 'moment';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { useRecoilState, useRecoilValue } from 'recoil';

import ButtonDefault, { ButtonVariants } from '../../shared/basic/button';
import { ShouldRender } from '../../shared/basic/ShouldRender';
import CustomLoader from '../../shared/Loading';
import { LogoWithoutSquare, ZebraLearnLogo } from '../../../../assets';
import { iframeEmail } from '../../../atoms/iframeEmail';
import { user } from '../../../atoms/user';
import appwriteService from '../../../config/appwrite';
import { logEvent } from '../../../hooks/useClickEventTracking';
import { Endpoints } from '../../../network';
import {
  getBookByIdApiIFrame,
  tempUserIFrame,
} from '../../../services/IFrame/apis';
import {
  getAllChaptersApi,
  getBookByIdApi,
} from '../../../services/Library/Books';
import { MyBookByIDInterface } from '../../../utils/interfaces/Library/Books';
import { AllChaptersListInterface } from '../../../utils/interfaces/Library/Pdf';
import { User } from '../../../utils/interfaces/User';

interface RotationsState {
  [key: number]: number;
}

const { Content, Header } = Layout;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface Props {
  isIFrame?: boolean;
  noAuth?: boolean;
  theme?: string;
  isModal?: boolean;
  handleCancel?: () => void;
}

const PDFReaderIframe: React.FC<Props> = ({
  isIFrame = false,
  noAuth = false,
  theme,
  isModal = false,
  handleCancel,
}) => {
  const router = useRouter();
  const [pageDimensions, setPageDimensions] = useState({ width: 0, height: 0 });
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1.0);
  const [rotations, setRotations] = useState<RotationsState>({});
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [sideBySide, setSideBySide] = useState(true);
  const [isHovered, setIsHovered] = useState(true);
  const [zoomValue, setZoomValue] = useState(100);
  const [samplePdf, setSamplePdf] = useState<string>();
  const [, setVisibleControl] = useRecoilState(iframeEmail);
  const [windowWidth, setWindowWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth : 0
  );
  const [timeStamp, setTimeStamps] = useState({
    startTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
    endTime: '',
  });
  const [windowHeight, setWindowHeight] = useState(
    typeof window !== 'undefined' ? window.innerHeight : 0
  );
  const controls = useAnimation();

  const [chapterFieldId, setChapterFieldId] = useState('');
  const [startPage, setStartPage] = useState<number>(0);

  const [chaptersList, setChaptersList] = useState<AllChaptersListInterface>();
  const [booksDetails, setBooksDetails] = useState<MyBookByIDInterface>();
  const [isInteracted, setIsInteracted] = useState(false);
  // const [chapterFileToken, setChapterFileToken] = useState('')
  const [renderPdf, setRenderPdf] = useState('');
  const userData: User | undefined = useRecoilValue(user);
  const containerRef = useRef<HTMLDivElement>(null);
  const baseUrl = process.env.NEXT_PUBLIC_API_READER_URL;
  const screens = useBreakpoint();
  const samplePdfValue = 'true';

  const {
    chapterPageNumber,
    chapterId,
    book_id,
    email,
    code: afc,
    productId,
  } = router.query;

  // Calculate total pages
  const chapters = chaptersList?.chapters || [];
  const totalNumberOfPages = chapters.reduce(
    (total, chapter) => total + chapter.totalPages,
    0
  );

  const onDocumentLoadSuccess = async ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    pdfjs.getDocument(samplePdf ?? renderPdf).promise.then((pdf) => {
      pdf.getPage(1).then((page) => {
        const { width, height } = page.getViewport({ scale: 1 });
        setPageDimensions({ width, height });
      });
    });
  };

  const rotateLeft = (pageNumber: number) => {
    setRotations((prevRotations) => ({
      ...prevRotations,
      [pageNumber]: (prevRotations[pageNumber] || 0) - 90,
    }));
  };

  const rotateRight = (pageNumber: number) => {
    setRotations((prevRotations) => ({
      ...prevRotations,
      [pageNumber]: (prevRotations[pageNumber] || 0) + 90,
    }));
  };

  const getNextPage = async () => {
    if (pageNumber + 1 <= (numPages || 1)) {
      const totalPages = chaptersList?.chapters?.find(
        (item: any) => item._id === chapterFieldId
      )?.totalPages;

      if (sideBySide) {
        if ((totalPages || 0) < pageNumber + 1 + 2 && !samplePdf) {
          return totalPages;
        } else {
          return pageNumber + 2;
        }
      } else {
        return pageNumber + 1;
      }
    }

    const totalPages = chaptersList?.chapters?.find(
      (item: any) => item._id === chapterFieldId
    )?.totalPages;

    if (totalPages === pageNumber + 1 || totalPages === pageNumber) {
      const currentIndex = chaptersList?.chapters.findIndex(
        (item: any) => item._id === chapterFieldId
      );

      if (
        currentIndex !== undefined &&
        currentIndex !== -1 &&
        currentIndex + 1 < (chaptersList?.chapters?.length ?? 0)
      ) {
        const nextChapter = chaptersList?.chapters?.[currentIndex + 1];

        if (nextChapter) {
          setRenderPdf(
            `${baseUrl}/${Endpoints.getChapterFile}/${nextChapter._id}`
          );
          setScale(1.0);
          setZoomValue(100);

          setChapterFieldId(nextChapter?._id as string);
          setStartPage(nextChapter?.startPage);
          return 1;
        } else {
          console.log('No next chapter available.');
        }
      } else {
        console.log('No next chapter available.');
      }
    }

    return pageNumber;
  };

  const getPreviousPage = async () => {
    if (pageNumber > 1) {
      return sideBySide ? pageNumber - 2 : pageNumber - 1;
    }

    // Find the index of the current chapter in the array
    const currentIndex = chaptersList?.chapters.findIndex(
      (item: any) => item._id === chapterFieldId
    );

    if (1 === pageNumber && currentIndex !== undefined && currentIndex !== -1) {
      // Check if there is a previous chapter
      if (currentIndex - 1 >= 0) {
        const prevChapter = chaptersList?.chapters?.[currentIndex - 1];

        if (prevChapter) {
          setRenderPdf(
            `${baseUrl}/${Endpoints.getChapterFile}/${prevChapter._id}`
          );

          setScale(1.0);
          setZoomValue(100);

          setChapterFieldId(prevChapter?._id as string);
          setStartPage(prevChapter?.startPage);
          // setStartPage(prevChapter?.startPage,startPage + pageNumber - 1);

          setPageNumber(prevChapter?.totalPages - 1);
          return prevChapter?.totalPages;
          // setPageNumber(totalPages); // Set the page number accordingly
        } else {
          console.log('No previous chapter available.');
        }
      } else {
        console.log('No previous chapter available.');
      }
    } else {
      console.log('No previous chapter available.');
    }

    return pageNumber;
  };

  const handlePageTransition = async (newPageNumber: number) => {
    if (newPageNumber >= 1 && newPageNumber <= (numPages || 1)) {
      // await controls.start({ opacity: 0 });
      setPageNumber(newPageNumber);
      // await controls.start({ opacity: 1 });
    }
  };

  // useEffect(() => {
  //   if (isFullScreen) {
  //     setScale(1);
  //   } else {
  //     setScale(0.95);
  //   }
  // }, [isFullScreen]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  // const handleMouseLeave = () => {
  //   setIsHovered(false);
  // };

  // const removeBookmark = (page: number) => {
  //   setBookmarks(bookmarks.filter((bookmark) => bookmark.page !== page));
  // };

  const zoomIn = () => {
    if (scale < 5.0) {
      const newScale = scale + 0.1;
      handleZoomChange(newScale);
    }
  };

  const zoomOut = () => {
    // if (scale > 0.5) {
    const newScale = scale - 0.1;
    handleZoomChange(newScale);
    // }
  };

  // useEffect(() => {
  //   if (screens.md) {
  //     setScale(1.4);
  //     setZoomValue(140);
  //   } else if (screens.sm) {
  //     setScale(1);
  //     setZoomValue(100);
  //   } else if (screens.xs) {
  //     setScale(0.8);
  //     setZoomValue(80);
  //   }
  // }, [book_id, screens]);

  const handleGetDefaultBookById = async (chapterIdUrl: string) => {
    try {
      setRenderPdf(
        `${baseUrl}/${Endpoints.getChapterFile}/${chapterIdUrl as string}`
      );

      setScale(1.0);
      setZoomValue(100);
    } catch (error) {
      if (process.env.NEXT_PUBLIC_APP_ENV === 'development') {
        console.error('Error initializing messaging:', error);
      }
    }
  };

  // useEffect(() => {
  //   if (chapterId) {
  //     handleGetDefaultBookById(chapterId as string);
  //     setChapterFieldId(chapterId as string);
  //   }
  //   if (chapterPageNumber) {
  //     setPageNumber(Number(chapterPageNumber));
  //   }
  // }, [chapterPageNumber, chapterId]);

  const toggleFullScreen = () => {
    const layout = document.querySelector('.ant-layout') as any;

    if (layout) {
      if (!isFullScreen) {
        const fullscreenFunction =
          layout.requestFullscreen ||
          layout.webkitRequestFullscreen ||
          layout.msRequestFullscreen ||
          layout.mozRequestFullScreen;

        if (fullscreenFunction) {
          fullscreenFunction.call(layout);
        }
      } else {
        const docs = document as any;
        const exitFullscreenFunction =
          docs.exitFullscreen ||
          docs.webkitExitFullscreen ||
          docs.msExitFullscreen ||
          docs.mozCancelFullScreen;

        if (exitFullscreenFunction) {
          exitFullscreenFunction.call(docs);
        }
      }
    }

    setIsFullScreen(!isFullScreen);
  };

  useEffect(() => {
    const handleKeyDown = async (event: KeyboardEvent) => {
      try {
        if (event.key === 'ArrowLeft') {
          const previousPage = await getPreviousPage();
          handlePageTransition(previousPage);
        } else if (event.key === 'ArrowRight') {
          const nextPage = await getNextPage();
          handlePageTransition(nextPage as number);
        }
      } catch (error) {
        if (process.env.NEXT_PUBLIC_APP_ENV === 'development') {
          console.error('Error initializing messaging:', error);
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [pageNumber]);

  useEffect(() => {
    const handleFullScreenChange = () => {
      setIsFullScreen(
        !!document.fullscreenElement ||
          !!(document as any).webkitFullscreenElement ||
          !!(document as any).msFullscreenElement ||
          !!(document as any).mozFullScreenElement
      );
    };

    document.addEventListener('fullscreenchange', handleFullScreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullScreenChange);
    document.addEventListener('msfullscreenchange', handleFullScreenChange);
    document.addEventListener('mozfullscreenchange', handleFullScreenChange);

    return () => {
      // Clean up the event listeners when the component unmounts
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
      document.removeEventListener(
        'webkitfullscreenchange',
        handleFullScreenChange
      );
      document.removeEventListener(
        'msfullscreenchange',
        handleFullScreenChange
      );
      document.removeEventListener(
        'mozfullscreenchange',
        handleFullScreenChange
      );
    };
  }, []);

  const toggleSideBySide = () => {
    setSideBySide(!sideBySide);
  };
  // const goToBookmark = (pageNum: number) => {
  //   console.log(pageNum);
  //   // setPageNumber(pageNum);
  // };

  //get chapter list api call
  const getAllChaptersListData = async (book_id: string) => {
    try {
      const response = await getAllChaptersApi(book_id);
      setChaptersList(response);

      if (!(chapterPageNumber && chapterId)) {
        setRenderPdf(
          `${baseUrl}/${Endpoints.getChapterFile}/${response?.chapters[0]?._id}`
        );
        setScale(1.0);
        setZoomValue(100);

        setChapterFieldId(response?.chapters[0]?._id as string);
        setStartPage(response?.chapters[0]?.startPage);
      } else {
        setStartPage(
          response?.chapters.find((chapter: any) => chapter._id === chapterId)
            ?.startPage as number
        );
        if (chapterPageNumber && response?.chapters) {
          setPageNumber(
            Number(chapterPageNumber) -
              (response?.chapters?.find(
                (chapter: any) => chapter._id === chapterId
              )?.startPage || 0) +
              1
          );
        }
      }

      // response?.chapters.map((chapter: any) => {
      //   setRenderPdf(
      //     `${baseUrl}${Endpoints.getChapterFile}/${
      //       chapter?._id
      //     }?token=${encodeURIComponent(response?.token)}`
      //   );
      // });
      // setRenderPdf(`${baseUrl}${Endpoints.getChapterFile}/${chaptersList?.chapters?._id}?token=${chapterFileToken}`)
    } catch (error) {
      if (process.env.NEXT_PUBLIC_APP_ENV === 'development') {
        console.error('Error initializing messaging:', error);
      }
    }
  };
  // get book details api call

  const getBookDetailsById = async (bookId: string) => {
    try {
      if (isIFrame) {
        const response = await getBookByIdApiIFrame(bookId);
        setBooksDetails(response);
      } else {
        const response = await getBookByIdApi(bookId);
        setBooksDetails(response);
      }
    } catch (error) {
      if (process.env.NEXT_PUBLIC_APP_ENV === 'development') {
        console.error('Error initializing messaging:', error);
      }
    }
  };

  const handleGetSampleBookById = async (bookId: string) => {
    try {
      if (isModal) {
        setSamplePdf(`${baseUrl}/${Endpoints.getSampleBook}/${bookId}`);
      } else {
        setSamplePdf(
          `${baseUrl}/${Endpoints.getSampleFileForBooksByProductId}/${bookId}`
        );
      }
    } catch (error) {
      if (process.env.NEXT_PUBLIC_APP_ENV === 'development') {
        console.error('Error initializing messaging:', error);
      }
    }
  };

  const handleGetDefaultBookByChapterId = async () => {
    try {
      if (chapterId) {
        handleGetDefaultBookById(chapterId as string);
        setChapterFieldId(chapterId as string);
      }
    } catch (error) {
      if (process.env.NEXT_PUBLIC_APP_ENV === 'development') {
        console.error('Error initializing messaging:', error);
      }
    }
  };

  useEffect(() => {
    if (chapterPageNumber && chapterId) {
      getAllChaptersListData(book_id as string);
      handleGetDefaultBookByChapterId();

      getBookDetailsById(book_id as string);
    } else if (samplePdfValue && book_id) {
      handleGetSampleBookById(book_id as string);
    } else if (samplePdfValue && productId) {
      handleGetSampleBookById(productId as string);
    } else if (book_id) {
      getAllChaptersListData(book_id as string);
      getBookDetailsById(book_id as string);
    }
  }, [book_id, samplePdfValue, chapterPageNumber, chapterId, productId]);

  const handleReadingLogs = async () => {
    try {
      const duration = moment().diff(timeStamp.startTime, 'milliseconds');

      // Base event details
      const baseEventParams = {
        event_name: 'read',
        source: 'books',
        source_type: router.query['cs']
          ? 'comingSoon'
          : router.query['swp']
          ? 'short'
          : 'long',
        source_id: productId as string,
        sub_source: samplePdf ? 'sample' : 'chapter',
        sub_source_id: samplePdf ? null : chapterFieldId,
        meta: {
          book_page_no: samplePdf ? pageNumber : startPage + pageNumber - 1,
          ...(!samplePdf && { chapter_page_no: pageNumber }),
        },
      };

      // Function to log event with specific end time
      const logReadEvent = (
        start: Moment,
        end: Moment,
        meta: Record<string, any>
      ) => {
        logEvent({
          eventDetails: {
            ...baseEventParams,
            activity_start: start.format('YYYY-MM-DDTHH:mm:ss'),
            activity_end: end.format('YYYY-MM-DDTHH:mm:ss'),
            meta,
          },
          userData,
          pathname: router.pathname,
        });
      };

      if (sideBySide) {
        const halfDuration = duration / 2;
        const firstEventEndTime = moment(timeStamp.startTime)
          .clone()
          .add(halfDuration, 'milliseconds');
        const firstMeta = {
          book_page_no: samplePdf ? pageNumber - 2 : startPage + pageNumber - 3,
          ...(!samplePdf && { chapter_page_no: pageNumber - 2 }),
        };

        const secondMeta = {
          book_page_no: samplePdf ? pageNumber - 1 : startPage + pageNumber - 2,
          ...(!samplePdf && { chapter_page_no: pageNumber - 1 }),
        };
        logReadEvent(moment(timeStamp.startTime), firstEventEndTime, firstMeta);
        logReadEvent(firstEventEndTime, moment(), secondMeta);
      } else {
        const meta = {
          book_page_no: samplePdf ? pageNumber - 1 : startPage + pageNumber - 2,
          ...(!samplePdf && { chapter_page_no: pageNumber - 1 }),
        };
        logReadEvent(moment(timeStamp.startTime), moment(), meta);
      }

      setTimeStamps({
        startTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
        endTime: '',
      });
    } catch (error) {
      if (process.env.NEXT_PUBLIC_APP_ENV === 'development') {
        console.error('Error initializing messaging:', error);
      }
    }
  };

  useEffect(() => {
    if (chapterFieldId || samplePdf) {
      handleReadingLogs();
    }
  }, [pageNumber]);

  const onChapterHandler = async (chapterId: string, startPage?: number) => {
    setChapterFieldId(chapterId);
    setStartPage(startPage as number);

    setRenderPdf(`${baseUrl}/${Endpoints.getChapterFile}/${chapterId}`);
    setPageNumber(1);
    setScale(1.0);
    setZoomValue(100);
  };

  const handleZoomChange = (newScale: any) => {
    setScale(newScale);
    setZoomValue(Math.round(newScale * 100));
  };

  const handleZoom = (event: any) => {
    handleZoomChange(event?.state?.scale);
  };

  useEffect(() => {
    if (!screens.lg) {
      setSideBySide(false);
    } else {
      setSideBySide(true);
    }
  }, [screens]);

  useEffect(() => {
    if (numPages && pageNumber + 1 > (numPages as number) && sideBySide) {
      setSideBySide(false);
    }
  }, [
    pageNumber,
    startPage,
    totalNumberOfPages,
    sideBySide,
    numPages,
    screens,
    samplePdfValue,
    pageDimensions,
  ]);

  useEffect(() => {
    const handleTouchStart = () => {
      setIsInteracted(true);
    };

    const handleTouchMove = () => {
      setIsInteracted(true);
    };

    const handleTouchEnd = () => {
      setIsInteracted(true);
    };

    window.addEventListener('touchstart', handleTouchStart);
    window.addEventListener('touchmove', handleTouchMove);
    window.addEventListener('touchend', handleTouchEnd);

    return () => {
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener('touchend', handleTouchEnd);
    };
  }, []);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsInteracted(false);
    }, 5000);
    return () => clearTimeout(timeout);
  }, [isHovered, isInteracted]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      setWindowHeight(container.clientHeight);
      setWindowWidth(container.clientWidth);
    }
  }, []);

  const isPortraitMode = useMemo(() => {
    return pageDimensions.width > pageDimensions.height;
  }, [pageDimensions]);

  const handleEmailLoginTempIFrame = async (emailValue: string) => {
    try {
      await appwriteService.logoutIfSessionExists();
      const session = await appwriteService.createAnonymousSession();
      if (session && typeof window !== undefined) {
        await tempUserIFrame({
          code: afc as string,
          email: emailValue as string,
          product_id: book_id as string,
          source: window.location.href,
          user_id: session?.$id,
        });
      }
      if (session?.$id && typeof localStorage !== 'undefined') {
        localStorage.setItem('user_id_iframe', session?.$id);
      }
    } catch (error: any) {
      if (error instanceof AppwriteException) {
        if (process.env.NODE_ENV === 'development') {
          console.error('Error initializing messaging:', error);
        }
      }
    }
  };

  useEffect(() => {
    if (email && !noAuth) {
      if (typeof localStorage !== 'undefined') {
        const user_id_iframe = localStorage.getItem('user_id_iframe');
        if (!user_id_iframe) {
          handleEmailLoginTempIFrame(email as string);
        }
      }
    }
  }, [email, router]);

  const openModal = async () => {
    let session = undefined;
    if (typeof localStorage !== 'undefined') {
      session = localStorage.getItem('user_id_iframe');
    }

    if (!email && pageNumber >= 5 && !noAuth && !session) {
      setVisibleControl(true);
    }
  };

  useEffect(() => {
    openModal();
  }, [email, router, pageNumber]);

  return (
    <Layout
      className={clsx(
        '  h-max bg-transparent',
        isHovered
          ? isFullScreen
            ? ' min-h-[100vh]'
            : ' min-h-[85vh]'
          : isFullScreen
          ? ' min-h-[100vh]'
          : 'min-h-[90vh]'
      )}
      style={{
        backgroundColor: 'transparent',
      }}
      ref={containerRef}
    >
      <ShouldRender check={isHovered}>
        <Affix offsetTop={0}>
          <Header
            className={clsx(
              !theme && !isModal
                ? 'bg-bgColor bg-opacity-75 backdrop-filter  backdrop-blur-md '
                : 'bg-bgColor ',

              `   z-40 w-full transition-all `
            )}
            style={{ minHeight: '40px', width: '100%' }}
          >
            <div className='flex text-fadedWhite justify-between items-center h-full '>
              <ShouldRender check={!isModal}>
                <div className='flex items-center w-full h-full gap-x-4 '>
                  <div
                    className={clsx(
                      'cursor-pointer h-4  flex  items-center w-full'
                    )}
                  >
                    <div>
                      <Image
                        src={screens.lg ? ZebraLearnLogo : LogoWithoutSquare}
                        alt='Logo'
                        height={14}
                        priority
                        className={clsx('object-contain   min-w-32h-full')}
                      />
                    </div>
                  </div>
                </div>
              </ShouldRender>
              <ShouldRender check={isModal}>
                <Button
                  onClick={() => {
                    handleCancel && handleCancel();
                  }}
                >
                  <div className='flex justify-center items-center gap-x-2 '>
                    <span>
                      <XIcon className='w-4' />
                    </span>
                    Close
                  </div>
                </Button>
              </ShouldRender>

              <ShouldRender check={screens.md}>
                <div className='flex w-full flex-col  justify-center items-center h-10'>
                  <span>{booksDetails?.title}</span>
                  {/* <span className='text-white flex text-sm gap-x-2 items-center'>
                <p className='underline'>{pageNumber}</p> <p>/</p>
                <p>{numPages}</p>
              </span> */}
                </div>
              </ShouldRender>

              <div className='flex w-full justify-end  gap-1 items-center'>
                <div className='flex gap-x-2 items-center'>
                  <span>
                    <Tooltip
                      title={`Zoom Out ${zoomValue}%`}
                      placement='bottom'
                    >
                      <div className='group'>
                        <button
                          onClick={zoomOut}
                          className='flex items-center translate-y-1 justify-center relative cursor-pointer group-hover:bg-gray-200 group-hover:bg-opacity-15  p-2  w-10 h-10 rounded-full'
                        >
                          <div className=' text-white '>
                            <ZoomOutOutlined style={{ fontSize: '20px' }} />
                          </div>
                        </button>
                      </div>
                    </Tooltip>
                  </span>
                  <span className='translate-y-1 '>
                    <Tooltip title={`Zoom In ${zoomValue}%`} placement='bottom'>
                      <div className='group '>
                        <button
                          onClick={zoomIn}
                          className='flex items-center justify-center relative cursor-pointer group-hover:bg-gray-200 group-hover:bg-opacity-15  p-2  w-10 h-10 rounded-full'
                        >
                          <div className=' text-white '>
                            <ZoomInOutlined style={{ fontSize: '20px' }} />
                          </div>
                        </button>
                      </div>
                    </Tooltip>
                  </span>
                </div>
                <ShouldRender check={!sideBySide && screens.md}>
                  <Tooltip title='Rotate Anti-Clockwise' placement='bottom'>
                    <div className='group'>
                      <button
                        onClick={() => rotateLeft(pageNumber)}
                        className='flex items-center  justify-center relative cursor-pointer group-hover:bg-gray-200 group-hover:bg-opacity-15  p-2  w-10 h-10 rounded-full'
                      >
                        <div className=' text-white '>
                          <RotateLeftOutlined style={{ fontSize: '20px' }} />
                        </div>
                      </button>
                    </div>
                  </Tooltip>
                  <Tooltip title='Rotate Clockwise' placement='bottom'>
                    <div className='group'>
                      <button
                        onClick={() => rotateRight(pageNumber)}
                        className='flex items-center  justify-center relative cursor-pointer group-hover:bg-gray-200 group-hover:bg-opacity-15  p-2  w-10 h-10 rounded-full'
                      >
                        <div className=' text-white '>
                          <RotateRightOutlined style={{ fontSize: '20px' }} />
                        </div>
                      </button>
                    </div>
                  </Tooltip>
                </ShouldRender>
                <ShouldRender check={screens.md}>
                  {/* <ShouldRender
                check={
                  screens.md && !(pageDimensions.width > pageDimensions.height)
                }
              > */}
                  <Tooltip title='Double Page' placement='bottom'>
                    <div className='group'>
                      <button
                        onClick={toggleSideBySide}
                        className='flex items-center  justify-center relative cursor-pointer group-hover:bg-gray-200 group-hover:bg-opacity-15  p-2  w-10 h-10 rounded-full'
                      >
                        <BookOpenIcon
                          className={clsx(
                            sideBySide ? 'text-primary' : 'text-white  '
                          )}
                        />
                      </button>
                    </div>
                  </Tooltip>
                </ShouldRender>

                {/* <Tooltip title='Bookmark' placement='bottom'>
                <div className='group'>
                  <button
                    onClick={() => showModal()}
                    className='flex items-center  justify-center relative cursor-pointer group-hover:bg-gray-200 group-hover:bg-opacity-15  p-2  w-10 h-10 rounded-full'
                  >
                    <BookmarkIcon
                      className={clsx('text-white  hover:text-primary')}
                    />
                  </button>
                </div>
              </Tooltip> */}
                <Tooltip title='Full screen' placement='bottom'>
                  <div className='group'>
                    <button
                      onClick={toggleFullScreen}
                      className={clsx(
                        isFullScreen ? ' text-primary' : 'text-white ',
                        'flex items-center  justify-center relative cursor-pointer group-hover:bg-gray-200 group-hover:bg-opacity-15  p-2  w-10 h-10 rounded-full'
                      )}
                    >
                      <div className='w-5 h-5 '>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 24 24'
                          fill='none'
                          className='fill-current'
                        >
                          <path d='M23 4C23 2.34315 21.6569 1 20 1H16C15.4477 1 15 1.44772 15 2C15 2.55228 15.4477 3 16 3H20C20.5523 3 21 3.44772 21 4V8C21 8.55228 21.4477 9 22 9C22.5523 9 23 8.55228 23 8V4Z' />
                          <path d='M23 16C23 15.4477 22.5523 15 22 15C21.4477 15 21 15.4477 21 16V20C21 20.5523 20.5523 21 20 21H16C15.4477 21 15 21.4477 15 22C15 22.5523 15.4477 23 16 23H20C21.6569 23 23 21.6569 23 20V16Z' />
                          <path d='M4 21H8C8.55228 21 9 21.4477 9 22C9 22.5523 8.55228 23 8 23H4C2.34315 23 1 21.6569 1 20V16C1 15.4477 1.44772 15 2 15C2.55228 15 3 15.4477 3 16V20C3 20.5523 3.44772 21 4 21Z' />
                          <path d='M1 8C1 8.55228 1.44772 9 2 9C2.55228 9 3 8.55228 3 8L3 4C3 3.44772 3.44772 3 4 3H8C8.55228 3 9 2.55228 9 2C9 1.44772 8.55228 1 8 1H4C2.34315 1 1 2.34315 1 4V8Z' />
                        </svg>
                      </div>
                    </button>
                  </div>
                </Tooltip>
                <Tooltip title='Hide Header' placement='bottom'>
                  <div className='group'>
                    <button
                      onClick={() => setIsHovered(!isHovered)}
                      className={clsx(
                        isHovered ? ' text-primary' : 'text-white ',
                        'flex items-center  justify-center relative cursor-pointer group-hover:bg-gray-200 group-hover:bg-opacity-15  p-2  w-10 h-10 rounded-full'
                      )}
                    >
                      <div className='w-5 h-5 '>
                        <EyeIcon />
                      </div>
                    </button>
                  </div>
                </Tooltip>
              </div>
            </div>
          </Header>
        </Affix>
      </ShouldRender>

      <Content>
        <div
          className={clsx(
            'bg-opacity-75 backdrop-filter backdrop-blur-md overflow-auto  relative flex min-h-[85vh]',
            isFullScreen ? 'min-h-[101vh] items-center' : 'min-h-[85vh]',
            theme && !isModal ? 'bg-white' : '',
            isModal && 'bg-transparent backdrop-blur-sm'
          )}
          style={{
            scrollBehavior: 'smooth',
            scrollbarWidth: 'none',
            backgroundColor: 'transparent',

            // paddingRight: `${isAtLeft ? 44 : leftPadding}px`,
          }}
        >
          <div className='w-full'>
            <div className='flex relative h-full  items-center'>
              <div
                onContextMenu={(e) => e.preventDefault()}
                className='w-full flex justify-center  items-center h-full relative overflow-auto'
                style={{
                  width: '100%',
                  // minHeight: '80vh',
                  overflow: 'auto',
                }}
              >
                <Document
                  className={clsx(
                    'pdf-container hideText w-full   h-full z-10'
                  )}
                  file={samplePdf ?? renderPdf}
                  rotate={rotations[pageNumber]}
                  loading={
                    <div className=' flex items-center h-screen w-full  justify-center'>
                      <CustomLoader />
                    </div>
                  }
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <div className='flex justify-center w-full  items-center h-full'>
                    <TransformWrapper
                      disablePadding={true}
                      panning={{
                        lockAxisY: false,
                        excluded: ['lockAxisX'],
                      }}
                      initialScale={scale}
                      wheel={{
                        disabled: true,
                      }}
                      onZoom={handleZoom}
                      doubleClick={{
                        disabled: true,
                      }}
                    >
                      <TransformComponent>
                        {sideBySide ? (
                          <motion.div
                            className='w-full flex justify-center items-center  h-full'
                            initial={{ x: 0 }}
                            animate={controls}
                          >
                            <div
                              className=' flex justify-center items-center gap-x-1 '
                              onMouseEnter={handleMouseEnter}
                              // onMouseLeave={handleMouseLeave}
                            >
                              <motion.div className='w-full flex justify-end z-10  '>
                                <Page
                                  pageNumber={pageNumber}
                                  scale={scale}
                                  height={windowHeight as number}
                                  width={Math.floor(
                                    windowWidth * (screens.xl ? 0.4 : 0.5) -
                                      (isModal ? 150 : 0)
                                  )}
                                  error={
                                    <div className='text-gray-400 text-lg text-center px-8'>
                                      <span className='flex justify-center'>
                                        <p className='w-2/3'>
                                          Please ensure your internet connection
                                          is stable and proceed to refresh the
                                          page.
                                        </p>
                                      </span>
                                      <span className='flex justify-center pt-2'>
                                        <ButtonDefault
                                          size={1}
                                          variant={ButtonVariants.UNSTYLED}
                                          className='w-full md:w-min p-1 disabled:cursor-not-allowed rounded bg-fadedWhite hover:bg-gray-100 text-black disabled:bg-dropDownMenuBg disabled:text-[#3E3E3E] disabled:border-0'
                                          onClick={() => {
                                            onChapterHandler(
                                              chapters[0]?._id as string,
                                              chapters[0]?.startPage
                                            );
                                          }}
                                        >
                                          <span className='flex text-sm  gap-x-2 px-2 font-bold whitespace-nowrap  '>
                                            Retry
                                          </span>
                                        </ButtonDefault>
                                      </span>
                                    </div>
                                  }
                                />
                              </motion.div>
                              <motion.div className='w-full flex justify-start z-10'>
                                <Page
                                  pageNumber={pageNumber + 1}
                                  scale={scale}
                                  height={windowHeight as number}
                                  width={Math.floor(
                                    windowWidth * (screens.xl ? 0.4 : 0.5) -
                                      (isModal ? 150 : 0)
                                  )}
                                  error={
                                    <div className='text-gray-400 text-lg text-center px-8'>
                                      <span className='flex justify-center'>
                                        <p className='w-2/3'>
                                          Please ensure your internet connection
                                          is stable and proceed to refresh the
                                          page.
                                        </p>
                                      </span>
                                      <span className='flex justify-center pt-2'>
                                        <ButtonDefault
                                          size={1}
                                          variant={ButtonVariants.UNSTYLED}
                                          className='w-full md:w-min p-1 disabled:cursor-not-allowed rounded bg-fadedWhite hover:bg-gray-100 text-black disabled:bg-dropDownMenuBg disabled:text-[#3E3E3E] disabled:border-0'
                                          onClick={() => {
                                            onChapterHandler(
                                              chapters[0]?._id as string,
                                              chapters[0]?.startPage
                                            );
                                          }}
                                        >
                                          <span className='flex text-sm  gap-x-2 px-2 font-bold whitespace-nowrap  '>
                                            Retry
                                          </span>
                                        </ButtonDefault>
                                      </span>
                                    </div>
                                  }
                                />
                              </motion.div>
                            </div>
                          </motion.div>
                        ) : (
                          <motion.div
                            className='w-max flex justify-center items-center'
                            initial={{ x: 0 }}
                            animate={controls}
                            style={{
                              width: 'fit-content',
                            }}
                          >
                            <div
                              onMouseEnter={handleMouseEnter}
                              className='flex items-center'
                            >
                              <Page
                                pageNumber={pageNumber}
                                scale={scale}
                                height={
                                  screens.lg
                                    ? windowHeight - 200
                                    : isPortraitMode
                                    ? windowHeight - 200
                                    : undefined
                                }
                                width={
                                  screens.lg
                                    ? isPortraitMode
                                      ? Math.floor(windowWidth * 0.7)
                                      : Math.floor(windowWidth * 0.4)
                                    : !isPortraitMode
                                    ? windowWidth
                                    : undefined
                                }
                                error={
                                  <div className='text-gray-400 text-lg text-center px-8'>
                                    <span className='flex justify-center'>
                                      <p className='w-2/3'>
                                        Please ensure your internet connection
                                        is stable and proceed to refresh the
                                        page.
                                      </p>
                                    </span>
                                    <span className='flex justify-center pt-2'>
                                      <ButtonDefault
                                        size={1}
                                        variant={ButtonVariants.UNSTYLED}
                                        className='w-full md:w-min p-1 disabled:cursor-not-allowed rounded bg-fadedWhite hover:bg-gray-100 text-black disabled:bg-dropDownMenuBg disabled:text-[#3E3E3E] disabled:border-0'
                                        onClick={() => {
                                          onChapterHandler(
                                            chapters[0]?._id as string,
                                            chapters[0]?.startPage
                                          );
                                        }}
                                      >
                                        <span className='flex text-sm  gap-x-2 px-2 font-bold whitespace-nowrap  '>
                                          Retry
                                        </span>
                                      </ButtonDefault>
                                    </span>
                                  </div>
                                }
                              />
                            </div>
                          </motion.div>
                        )}
                      </TransformComponent>
                    </TransformWrapper>
                  </div>
                </Document>
              </div>
            </div>
          </div>
        </div>
        <ShouldRender check={isHovered}>
          <div className='fixed bottom-[5%] left-1/2 transform w-auto -translate-x-1/2 p-2 md:p-3 font-lato font-semibold  text-white inline-flex items-center cursor-pointer opacity-85 bg-[#485056] rounded-lg'>
            <div className='flex w-max justify-center gap-x-2 items-center'>
              <ShouldRender
                check={
                  (samplePdf ? pageNumber : startPage + pageNumber - 1) !== 1
                }
              >
                <ChevronLeftIcon
                  onClick={async () => {
                    const previousPage = await getPreviousPage();
                    handlePageTransition(previousPage);
                  }}
                  className='text-2xl w-5 h-5 hover:scale-125'
                />
              </ShouldRender>
              <span className='inline-flex gap-x-2'>
                <p className='underline'>
                  {samplePdf ? pageNumber : startPage + pageNumber - 1}
                </p>{' '}
                <ShouldRender check={numPages || totalNumberOfPages}>
                  of {samplePdf ? numPages : totalNumberOfPages}
                </ShouldRender>
              </span>
              <ShouldRender
                check={
                  (samplePdf ? pageNumber : startPage + pageNumber - 1) !==
                  (samplePdf ? numPages : totalNumberOfPages)
                }
              >
                <ChevronRightIcon
                  onClick={async () => {
                    const nextPage = await getNextPage();
                    handlePageTransition(nextPage as number);
                  }}
                  className='text-xl w-5 h-5 hover:scale-125'
                />
              </ShouldRender>
            </div>
          </div>
        </ShouldRender>
      </Content>
    </Layout>
  );
};

export default PDFReaderIframe;
